@import '@styles/resources';

.badge-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f2f2;
  border-radius: 16px;
  font-family: $Mukta;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #2a2522;
  width: 34px;
  height: 27px;
}
