@import '@styles/resources';

.taskSection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  flex: 1;

  .taskChipWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .accordionMainHeader {
    margin-bottom: 16px;
    background-color: $color-white;

    .accordionHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;

      .accordionTitle {
        font-family: $Mukta;
        font-weight: $font-weight-500;
        font-size: $fs-14;
        text-transform: uppercase;
        color: $color-grey-6;
      }

      .accordionDivider {
        width: 100%;
        flex: 1;
      }
    }
  }

  .accordionContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $color-white;
    box-shadow: $box-shadow-1;
    border-bottom: 1px solid $color-grey-2;

    .accordionHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 24px;
      background: $color-white;
      border-bottom: 1px solid $color-grey-2;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }

        .pocWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;

          .uploadWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .divider {
          height: 38px;
          border-right: 1px solid $color-grey-1;
        }

        .editButton {
          color: $color-slate-brown-1;
        }
      }
    }
  }

  //   .footer {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     background: $color-white;
  //     box-shadow: $box-shadow-1;
  //     border-bottom-left-radius: 8px;
  //     border-bottom-right-radius: 8px;

  //     .textWrapper {
  //         padding: 16px 24px;
  //         flex: 1;
  //         display: flex;
  //         flex-direction: row;
  //         justify-content: center;
  //         align-items: center;
  //         gap: 16px;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         white-space: nowrap;
  //     }

  //     .textWrapperNew {
  //         padding: 16px 24px;
  //         flex: 1;
  //         display: flex;
  //         justify-content: flex-end;
  //         align-items: center;
  //         gap: 16px;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         white-space: nowrap;
  //     }
  // }
}
