@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 636px;
  max-height: 100%;
  overflow: hidden;
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    overflow: hidden;
    .fieldsWrapper {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex: 1;
      overflow: auto;
    }
    .rightButton {
      align-self: end;
      margin-right: 16px;
      margin-bottom: 16px;
      color: $color-red-1;
      border: 1px solid $color-red-1;
    }
    .textArea {
      min-height: 134px;
      align-items: baseline;
      overflow-x: scroll;
    }
  }
}
