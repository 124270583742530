@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 24px 0;
  .headerWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    gap: 24px;
  }
  .pocWrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    background-color: transparent;
    padding: 16px 1px;
    margin-bottom: 16px;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-grey-1;
      border-radius: 8px;
    }
  }
}
