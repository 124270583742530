@import '@styles/resources';

.mailSentWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  .mailSentTextWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    .mailSentText {
      font-family: $Mukta;
      font-size: $fs-18;
      font-weight: $font-weight-400;
      color: $elchemy-text-2;
    }
    .maskedMail {
      font-family: $Mukta;
      font-weight: $font-weight-700;
      font-size: $fs-18;
      color: $elchemy-text-2;
    }
  }
  .actionWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    .actionText {
      font-family: $Mukta;
      font-weight: $font-weight-400;
      font-size: $fs-18;
      color: $color-slate-brown-1;
    }
  }
}
