@import '@styles/resources';

.headerWrapper,
.btnWrapper {
  display: flex;
  justify-content: space-between;
}

.iconRed {
  color: red;
}
