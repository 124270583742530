@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white-1;
  padding-top: 24px;
  padding-inline: 72px;
  scroll-behavior: smooth;
  flex: 1;
  overflow: auto;
  .labelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .subMainWrapper {
    margin-top: 20px;
    display: flex;
    align-items: stretch;
    flex: 1;
    overflow: visible;
    border-top: 1px solid $color-grey-2;
    .sidebarWrapper {
      padding: 24px 24px 0 0;
      border-right: 1px solid $color-grey-2;
    }
  }
}
