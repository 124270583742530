@import '@styles/resources';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  gap: 24px;

  .rowWrapper {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }
  .actionBtnWrapper {
    margin-top: auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
}
