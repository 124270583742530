@import '@styles/resources';

.userCardContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  .statusContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 4px;
    padding-left: 24px;
  }
  .profileIcon {
    height: 40px;
    width: 40px;
  }
  .userCardDetailColumn {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 4px;
    .titleWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      .primaryTitleLabel {
        font-weight: $font-weight-400;
        font-size: $fs-16;
        color: $color-grey-6;
      }
    }
  }
}
