@import '@styles/resources';

.contentWrapper {
  display: flex;
  flex-direction: column;
  overflow: none;

  .titleWrapper {
    display: flex;
    flex-direction: column;
    margin: 24px 0 0 24px;

    .secondaryText {
      font-weight: $font-weight-400;
      font-size: $fs-16;
      color: var(--color-grey-6);
    }
  }

  .textWrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .emailLink {
    font-weight: 700;
    color: $color-red-2;
  }
  .message {
    text-align: center;
  }
}
