@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: row;
  padding: 24px;
  background-color: $color-white;
  height: auto;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  position: relative;
  padding-bottom: 50px;

  .sidebarIconItems {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sidebar {
    position: absolute;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: auto;
    flex-shrink: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: white;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-30%);
    border-right: 2px solid $color-grey-1;
  }

  .sidebarContainer {
    width: 230px;

    .closeButton {
      transition: transform 12s ease-in-out, opacity 12s ease-in-out;
    }
  }

  .sidebarOpen {
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  }

  .sidebarIcon {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    flex-shrink: 0;
  }

  .orderOverviewContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100vh;
    align-items: stretch;
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
    padding-left: 24px;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding-left: 36px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}