@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .rowWrapper {
      margin-top: 24px;
      flex: 1;
      display: flex;
      align-items: center;
      .columnWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        flex-basis: 50%;
        overflow: hidden;
      }
    }
  }
}
