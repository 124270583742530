@import '@styles/resources';

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  padding-block: 24px;
  flex: 1;
  .boxWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 28px;
    padding: 16px;
    .fieldWrapper {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 10px;
    }
    .blWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
      .blRadioWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
        padding-left: 50px;
      }
    }
    .remarkBoxContainer {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 16px;
      gap: 24px;
      min-height: fit-content;
      border: 1px solid $color-grey-2;
      border-radius: 8px;

      .remarks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
      }
    }
  }
  .actionButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttonContainer {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
.textField {
  width: max-content;
}
