@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .dividerWrapper {
      margin: 24px 0;
    }
  }
}

.itemMainWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &:last-of-type {
    .divider {
      display: none;
    }
  }
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .fieldSpacing {
    margin-top: 24px;
  }
  .fieldSpacing2 {
    margin-bottom: 0px;
    margin-right: 10px;
  }
  .rowWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .fieldWrapper {
      margin-right: 24px;
    }
  }
  .divider {
    margin-bottom: 16px;
  }
  .rowWrapper1 {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
    text-align: left;
    &:last-child {
      margin-bottom: 0;
    }
    .fieldWrapper {
      // margin-right: 24px;
      width: 100%;
    }
    .qualityTestWrapper {
      flex: 1;
      display: flex;
      gap: 8px;
    }
  }
  .pacakageSectionWrapper {
    .titleWrapper {
      margin-bottom: 12px;
    }
  }
}
