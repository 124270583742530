.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  .textWrapper {
    padding: 16px 24px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .textWrapperNew {
    padding: 16px 24px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .iconStyles {
    height: 38px;
    width: 38px;
    margin-left: 8px;
  }
}
