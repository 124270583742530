.sealSectionWrapper {
    margin-top: 24px;
    width: 100%;
    margin-right: 24px;
  
    .boxWrapper {
      padding: 16px;
      border: 1px solid #ccc;
      border-radius: 8px;
  
      .rowWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
  
        .fieldWrapper {
          flex: 1;
          margin-right: 16px;
        }
  
        .deleteButton {
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }
  
    .titleWrapper {
      margin-bottom: 8px;
    }
  }
  