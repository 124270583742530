.button-group-root {
  display: inline-flex;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  overflow: hidden;
  .button-group-children-horizontal {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-left: 1px solid #d0d5dd !important;
    &:first-child {
      border-left: none !important;
    }
  }
  .button-group-children-vertical {
    border: none !important;
    border-radius: 0 !important;
    border-top: 1px solid #d0d5dd !important;
    box-shadow: none !important;
    &:first-child {
      border-top: none !important;
    }
  }
}
