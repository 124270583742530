@import '@styles/resources';

.nestedChipWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px 4px 4px;
  background-color: $color-blue-2;
  border-radius: 16px;
  min-height: 29px;
  font-family: $Mukta;
  font-weight: $font-weight-400;
  font-size: $fs-14;
  color: $color-blue-1;
  cursor: default;
  .chipContainedWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    height: 21px;
    background: $color-blue-1;
    border-radius: 16px;
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-14;
    text-align: center;
    color: $color-white;
    .firstChipLabel {
      font: inherit;
      color: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .secondChipLabel {
    font: inherit;
    color: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
