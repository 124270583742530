@import '@styles/resources';

.boxWrapper {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .rowWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .fieldWrapper {
      margin-right: 24px;
    }
  }
}
