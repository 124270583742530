@import '@styles/resources';

.acknowledgeWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    .acknowledgeText {
      font-family: $Mukta;
      font-weight: $font-weight-400;
      font-size: $fs-18;
      color: $elchemy-text-2;
    }
  }
}
