@import '@styles/resources';

.productsContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  flex: 1;
  .productsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .noProductsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: auto;
    width: 317px;
    text-align: center;
  }
}
