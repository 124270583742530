@import '@styles/resources';

.documentNameWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reUploadWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .reUploadButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.actionButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .iconButton {
    cursor: pointer;
  }
}
.iconWordButton {
  filter: invert(100%);
  cursor: pointer;
}
.docRequired {
  color: var(--color-red-1);
  font-weight: 900;
}
