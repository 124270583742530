@import '@styles/resources';

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  flex-grow: 1;
  overflow: visible;
  padding: 24px;
  .actionButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actionButtonContainer {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}

@media screen and (max-width: $md-max) {
  .formWrapper {
    padding-inline: 0;
  }
}
