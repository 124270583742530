@import '@styles/resources';

.radio-button-icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  .radio-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    z-index: 1;
    cursor: inherit;
    &:disabled {
      pointer-events: none;
      user-select: none;
      cursor: not-allowed;
    }
  }
  .radio-ripple {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .radio-input:active + span.radio-ripple {
    box-shadow: $box-shadow-2;
  }
  .radio-icon {
    width: inherit;
    height: inherit;
  }
}

.radio-label-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  max-width: max-content;
  vertical-align: middle;
  font-family: $Mukta;
  font-weight: $font-weight-400;
  font-size: $fs-16;
  color: $color-slate-brown-1;
  user-select: none;
  .radio-label {
    font: inherit;
    color: inherit;
  }
}
