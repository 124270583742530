@import '@styles/resources';

.supplierDetailTabWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  flex: 1;
  .supplierDetailWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .supplierDetailContainer {
    display: flex;
    flex: 1;
    border-top: 1px solid $color-grey-2;
    .sideBar {
      padding: 24px 24px 0 0;
      border-right: 1px solid $color-grey-2;
    }
  }
}
