@import '@styles/resources';

.link-button-text,
.link-button-text-secondary {
  display: block;
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-500;
  line-height: 17px;

  text-decoration: none;
  cursor: pointer;
  width: max-content;
  user-select: none;
  &:hover {
    color: $color-red-8;
  }
  &:active {
    color: $color-red-1;
  }
}
.link-button-text-secondary {
  color: $color-grey-5;
}
.link-button-text {
  color: $color-red-1;
}

.btn-text-disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: $color-red-7;
  user-select: none;
}

.link-button-contained {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $Mukta;
  font-size: $fs-18;
  font-weight: $font-weight-400;
  color: $color-white;
  text-decoration: none;
  background-color: $color-red-1;
  border: $button-border;
  box-shadow: $button-shadow;
  border-radius: 8px;
  text-align: center;
  min-height: 49px;
  cursor: pointer;
  &:hover {
    background-color: $color-red-8;
  }
  &:active {
    background: $color-red-1;
    box-shadow: $box-shadow-2;
  }
}

.btn-contained-disabled {
  background-color: $color-red-7;
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
  border: none;
}

.link-button-outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $Mukta;
  font-size: $fs-18;
  font-weight: $font-weight-400;
  color: $color-black;
  text-decoration: none;
  background-color: $color-white;
  border: $button-border;
  box-shadow: $button-shadow;
  border-radius: 8px;
  text-align: center;
  min-height: 49px;
  cursor: pointer;
  &.red {
    color: #ef4136;
    padding: 0px 16px;
    font-size: $fs-16;
  }
}

.link-button-outlined-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 117px;
  gap: 8px;
  border: 1px solid $color-red-1;
  border-radius: 8px;
  background-color: transparent;
  font-family: $Mukta;
  font-weight: $font-weight-500;
  font-size: $fs-16;
  color: $color-red-1;
  text-decoration: none;

  &:hover {
    background-color: $color-white;
    border: 1px solid $color-red-8;
  }

  &:focus {
    box-shadow: $box-shadow-2;
  }

  &:disabled {
    border: 1px solid $color-red-7;
    color: $color-red-7;
    cursor: not-allowed;
  }
}
