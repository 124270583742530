.rfqcontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rfqTallyBackButton {
    padding: 32px;
}

.tallyIframe {
    padding-bottom: 10px;
}