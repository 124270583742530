@import '@styles/resources';

.boxWrapper {
  //   margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-grey-2);
  padding: 16px;
  .rowWrapper {
    margin-bottom: 16px;
    .fieldWrapper {
      margin-right: 24px;
    }
    .fieldWrapper1 {
      border-radius: 0;
      width: 125px;
    }
    .fieldWrapper2 {
      margin-right: 24px;
    }
  }
}
