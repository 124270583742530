@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .rowWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .fieldWrapper {
        margin-right: 24px;
      }
      .selectFieldWrapper {
        width: 60%;
      }
      .qualityTestWrapper {
        flex: 1;
        display: flex;
        gap: 8px;
      }
    }
    .rowWrapper1 {
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      justify-content: space-between;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
      .fieldWrapper {
        // margin-right: 24px;
        width: 100%;
      }

      .qualityTestWrapper {
        flex: 1;
        display: flex;
        gap: 8px;
      }
    }
    .headerWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    .dividerWrapper {
      margin-bottom: 12px;
    }
    h5 {
      margin-bottom: 12px;
    }
  }
}
