@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding: 0 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: 100%;

      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .title {
          text-transform: capitalize;
        }
      }

      .linkButton {
        font-size: 16px;
      }

      .pocWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .uploadWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .confirmBtn {
          border: 1px solid $color-red-1;
          color: $color-red-1;
        }
      }

      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
      }

      .editButton {
        color: $color-slate-brown-1;
      }
    }
  }
  .accordianContent {
    .docContainer {
      background: $color-grey-1;
    }
    .docTitle {
      color: $color-slate-brown-1;
    }
    .docContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-grey-1;
      color: $color-slate-brown-1;
      position: relative;
      .textWrapperNew {
        padding: 16px 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .uploadDocWrapper {
          display: flex;
          flex-direction: row;
          gap: 19px;
          align-items: flex-start;
          color: $color-slate-brown-1;
        }
      }
    }
    .textWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: c;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:last-child {
        align-self: stretch;
        margin-left: auto;
        border-left: 1px solid $color-grey-1;
        padding-left: 16px;
      }
    }
    .textWrapperNew {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .iconWrapper {
      position: absolute;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      .iconImg {
        display: inline-block;
        margin-right: 10px;
        vertical-align: bottom;
        cursor: pointer;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
