.spinner-wrapper {
  display: block;
  animation: spinner 2s linear infinite;
  width: 24px;
  height: 24px;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loader-wrapper {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .loader-spinner {
    width: 35px;
    height: 35px;
  }
}
