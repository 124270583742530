.backButton {
  display: flex;
  align-items: stretch;
  gap: 8px;
  text-decoration: none;
  border: 1px solid transparent;
}
.resetPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
}
