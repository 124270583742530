@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;

  &:last-of-type {
    margin-bottom: 16px;
  }

  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding-right: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    width: 100%;

    .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px
    }

    .pocWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      // .uploadWrapper {
      //   display: flex;
      //   flex-direction: row;
      //   align-items: center;
      // }
      .uploadWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .detailsButton {
          color: $color-red-1;
          border: 1px solid $color-red-1;
          padding: 5px 16px;
        }
      }
    }

    .divider {
      height: 38px;
      border-right: 1px solid $color-grey-1;
    }
  }

  .textWrapper {
    padding: 16px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: c;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
      align-self: stretch;
      margin-left: auto;
      border-left: 1px solid $color-grey-1;
      padding-left: 16px;
    }
  }

  .mainContent {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-grey-1;

    .pocWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;

      .poctextWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
      }
    }
  }

  .docContainer,
  .docContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-1;
    background: $color-grey-1;

    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .docContent {
    background-color: $color-white;
    color: $color-black;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .iconStyles,
  .dropIconStyles {
    height: 40px;
    width: 40px;
  }

  .dropIconStyles {
    border-left: 1px solid $color-grey-1 ;
  }
}

.docTitle {
  color: $color-slate-brown-1;
}