@import '@styles/resources';

.tasksWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 72px;
  background-color: $color-white-1;
  flex: 1;
  overflow: auto;
  scroll-behavior: smooth;
  .tasksHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tasksTextWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .tasksDivider {
    margin-top: 18px;
    margin-bottom: 20px;
  }
  .tasksFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
    .tasksFilterButton {
      width: 104px;
      height: 42px;
    }
    .tasksSearch {
      width: 350px;
      height: 40px;
    }
  }
  .tasksSection {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    margin-top: 24px;
    flex-grow: 1;
    .docHeaderItem {
      &:last-child {
        padding: 0;
      }
    }
  }
  .addProductButton {
    width: 125px;
    height: 42px;
  }
}

.tasksButton {
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
}
.doucmentCountWrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  text-align: center;
}
