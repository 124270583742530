@import '@styles/resources';

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  margin: auto;
  max-height: 100%;
  width: 416px;
  overflow: hidden;
  outline: none;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 8px;
    height: 62px;
    background-color: $color-grey-1;
    .modalCloseButton {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
  .modalForm {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    background-color: $color-white;
    overflow: hidden;
    flex: 1;
    .modalContent {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
      padding: 16px;
      flex: 1;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
      }
      .textFieldLabel {
        font-size: $fs-14;
        font-weight: $font-weight-500;
      }
      .documentUploadContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
        .uploadContainer {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          border: 1px solid $color-grey-2;
          border-radius: 8px;
          .uploadContainerRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-bottom: 1px solid $color-grey-2;
            &:last-child {
              border-bottom: none;
            }
            .uploadSuccess {
              margin-right: 6px;
            }
          }
        }
      }
    }
    .actionBtnWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1;
      padding: 16px;
      box-shadow: $box-shadow-1;
      .actionBtn {
        flex: 1;
      }
    }
  }
}
