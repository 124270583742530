@import '@styles/resources';

.mainWrapper {
  flex: 1;
  overflow: visible;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  .headerItemWrapper {
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(3) {
      justify-content: center;
    }
    &:last-child {
      flex: 0 !important;
      padding: 0 !important;
    }
  }
  .footerWrapper {
    background-color: $color-grey-1 !important;
    .columnWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 4px;
    }
  }
}

.mainRowWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 16.66%;
  overflow: hidden;
  .columnWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 4px;
  }
  .downloadButtonWrapper {
    margin-left: auto;
  }
}
