@import '@styles/resources';

.mainWrapper {
    margin-bottom: 24px;

    .boxWrapper {
        flex: 1;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
}