@import '@styles/resources';

.forgotWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  .formWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
