.accountSetup {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  .backButton {
    display: flex;
    align-items: stretch;
    gap: 8px;
    text-decoration: none;
    border: 1px solid transparent;
  }
  .passwordFormWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }
}
