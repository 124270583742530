@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding: 0 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: 100%;
      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .linkButton {
        font-size: 16px;
      }

      .pocWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        
        .uploadWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            .divider {
                height: 38px;
                border-right: 1px solid $color-grey-1;
                margin-left: 24px;
            }
            .detailsButton {
              color: $color-red-1;
              border: 1px solid $color-red-1;
              padding: 5px 16px;
            }
          }
      }

      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
      }

      .editButton {
        color: $color-slate-brown-1;
      }
    }
  }
  .accordionContent {
    .stickerDetailsWrapper {
      display: flex;
      flex-direction: column;
      .stickersInnerWrapper {
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        padding: 12px 24px;
        border-bottom: 1px solid $color-grey-1;
        .selectWrapper {
          max-width: 80px;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.productSection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  flex-grow: 1;
  .dataDesign {
    display: flex;
    flex-direction: column;
    padding: 32px;

    .container {
      display: flex;
      flex-direction: row;
      padding: 8px;
      width: 100%;
      align-items: center;
      .productLabel {
        width: 15%;
      }

      .dropDown {
        width: 40%;
      }
    }
  }


  .docHeaderItem {
    &:last-child {
      padding: 0;
    }
  }
}
.supplierSelect{
  width: 70%;
  margin: 0 auto;
}