@import '@styles/resources';

.uploadWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  height: 250px;
  background: $color-white;
  border: 1px dashed $color-grey-2;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  margin-top: 8px;
  &:active {
    border: 1px dashed $color-red-1;
    box-shadow: $box-shadow-2;
  }
  .uploadIcon {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 25px;
    height: 25px;
    margin-bottom: 12px;
    user-select: none;
  }
  .userUploadIcon {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 40px;
    height: 40px;
    margin-bottom: 12px;
    user-select: none;
  }
  .uploadTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    text-align: center;
    .uploadtextContainer {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      text-align: center;
      .boldUploadText {
        font-weight: $font-weight-600;
        color: $color-slate-brown-1;
        margin-right: 6px;
        text-align: center;
      }
    }
  }
  .uploadField {
    display: inline-block;
    word-wrap: break-word;
    margin-left: 20%;
    &::file-selector-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-family: $Mukta;
      font-weight: $font-weight-500;
      font-size: $fs-16;
      color: $color-slate-brown-1;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.DragDropLabel {
  font-family: $Mukta;
  font-weight: $font-weight-400;
  font-size: $fs-16;
  color: $color-slate-brown-1;
  &::after {
    content: attr(data-value);
    color: $color-red-1;
    font-family: $Mukta;
    font-size: $fs-16;
    font-weight: $font-weight-400;
  }
}
.fieldHelperText {
  font-family: $Mukta;
  font-weight: $font-weight-400;
  font-size: $fs-14;
  color: $color-red-1;
}
.helperTextError {
  color: $color-red-1 !important;
}