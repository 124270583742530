@import '@styles/resources';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  gap: 24px;
  .bankDetailCountWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .boxWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    .buttonWrapper {
      margin-top: 16px;
      align-self: flex-start;
    }
    .fieldWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      &:last-of-type {
        .divider {
          display: none;
        }
      }
    }
    .rowWrapper {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
      .dummyFieldWrapper {
        flex: 1;
      }
    }
  }
  .actionBtnWrapper {
    margin-top: auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
}

// @import '@styles/resources';

.accordionBodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 16px;
  .bankDetailCountWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detailWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    margin-bottom: 12px;
    .fieldsWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
      .fieldWrapper {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
  }
  .divider:last-of-type {
    display: none;
  }
}

