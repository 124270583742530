@import '@styles/resources';

.MuiTab-root {
  text-transform: initial !important;
  font-family: $Mukta !important;
  font-size: $fs-18 !important;
  font-weight: $font-weight-400 !important;
  min-height: auto !important;
  height: 42px;
}
.Mui-selected {
  font-family: $Mukta;
  font-weight: $font-weight-700 !important;
  font-size: $fs-18;
  color: #2a2522 !important;
}
