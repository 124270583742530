@import '@styles/resources';

.bankInfoContainer {
  flex: 1;
  padding: 24px;
  .detailBoxWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    .bankRowWrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;
      .bankLabel {
        font-family: $Mukta;
        font-size: $fs-16;
        font-weight: $font-weight-600;
        color: $color-slate-brown-1;
        margin-bottom: 12px;
      }
      &:last-child {
        hr {
          display: none;
        }
      }
      .rowWrapper {
        display: flex;
        align-items: center;
        flex: 1;
        margin-bottom: 24px;
        .textColumnWrapper {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 4px;
          flex: 1;
          border-left: 1px solid $color-grey-2;
          padding-left: 16px;
          &:first-child {
            border-left: none;
            padding-left: 0;
          }
        }
      }
      .bankAddressWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        gap: 8px;
        margin-bottom: 24px;
        .linkLabel {
          font-family: $Mukta;
          font-size: $fs-16;
          font-weight: $font-weight-400;
          color: $color-slate-brown-1;
          text-decoration: none;
        }
      }
    }
  }
}
