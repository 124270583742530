@import '@styles/resources';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .rowWrapper {
      margin: 16px 0;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .fieldSpacing {
      flex: unset;
      flex-basis: 50%;
      margin: 16px 0;
    }
  }
  .actionButtonWrapper {
    margin-top: auto;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actionButtonContainer {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
