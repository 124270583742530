@import '@styles/resources';

.mainWrapper {
  margin-bottom: 16px;

  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .mainDocContainer {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border: 1px solid $color-grey-2;
      border-radius: 8px;
      height: 204px;
    }

    .addMoreBtn {
      margin: 24px 0 0 0;
      align-self: flex-start;
    }
  }
}

.docRowWrapper {
  padding: 16px;
  max-height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .docFirstColumn {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .docSecondColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    .viewWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      color: $color-red-1;
    }
    .deleteIcon {
      cursor: pointer;
    }
  }

  .requiredIcon {
    color: var(--color-red-4);
  }
}
