@import '@styles/resources';

.mainWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 16px;
  .headerWrapper {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
