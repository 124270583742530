@import '@styles/resources';

.navItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 38px;
  .navActive {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 100%;
    width: 100%;
    border-bottom: 2px solid $color-slate-brown-1;
    font-family: $Mukta;
    font-weight: $font-weight-700;
    font-size: $fs-18;
    color: $color-slate-brown-1;
  }
  .navInactive {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 100%;
    width: 100%;
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-18;
    color: $color-grey-6;
  }
}
