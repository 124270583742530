@import '@styles/resources';
@import 'react-day-picker/dist/style.css';

.rdp {
  --rdp-accent-color: #ef4136;
  --rdp-caption-font-size: 1.125rem;
  --rdp-background-color: #fff1f0;
  margin: 1em;
  font-family: $Mukta;
  font-size: $fs-14;
  font-weight: $font-weight-400;
  color: $color-slate-brown-1;
}

.rdp-months {
  justify-content: center;
  width: 100%;
}

.rdp-month {
  margin: 0 1em;
  width: 100%;
}

.rdp-table {
  margin: auto;
}

.rdp-caption_label {
  font-size: $fs-18;
  font-weight: $font-weight-700;
}

.rdp-head_cell {
  font-size: $fs-16;
  font-weight: $font-weight-500;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected, .rdp-day_outside) {
  color: $color-red-1;
}

.rdp-day_today:not(.rdp-day_outside, .rdp-day_selected) {
  color: $color-red-1;
}

.rdp-day_outside {
  color: $color-grey-8;
}
