@import '@styles/resources';

.userCardContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  padding: 16px 24px;
  margin: 16px;
  background: var(--color-white);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  max-width: 306px;

  .profileIcon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .userCardDetailColumn {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 4px;

    .primaryTitleLabel {
      font-weight: $font-weight-400;
      font-size: $fs-16;
      color: $color-slate-brown-1;
    }

    .secondaryText {
      font-weight: $font-weight-400;
      font-size: $fs-16;
      color: var(--color-grey-6);
    }
  }
}
