@import '@styles/resources';

.mainWrapper {
  flex: 1;
  padding: 24px;
  .subWrapper {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    .cardWrapper {
      flex: 1;
      border: 1px solid $color-grey-2;
      border-radius: 8px;
      box-shadow: none;
    }
  }
}
