.icon-node-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  .icon-node {
    display: block;
    object-fit: contain;
    object-position: center;
    width: auto;
    max-height: 100%;
  }
}
