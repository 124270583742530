@import '@styles/resources';

.input-root {
  display: inline-flex;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  max-height: 40px;
  background-color: $color-white;
  border: 1px solid $color-grey-4;
  box-shadow: $button-shadow;
  color: $color-slate-brown-1;
  border-radius: 8px;
  font-family: $Mukta;
  font-style: normal;
  font-weight: $font-weight-400;
  font-size: $fs-16;
  overflow: hidden;
  .input-box {
    display: block;
    min-width: 0;
    width: 100%;
    margin: 0;
    border: 0;
    outline: none;
    background-color: inherit;
    -webkit-tap-highlight-color: inherit;
    font: inherit;
    color: inherit;
    height: 100%;
    appearance: none;
    -webkit-appearance: none;
    &::placeholder {
      font: inherit;
      color: $elchemy-text-2;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:autofill,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-box-shadow: 0 0 0 1000px white inset;
      -moz-box-shadow: 0 0 0 1000px white inset;
      filter: none;
      caret-color: black;
      background-color: $color-white !important;
      color: $color-slate-brown-1 !important;
      font: inherit !important;
      cursor: text !important;
      -webkit-text-fill-color: $color-slate-brown-1 !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
    &[type='search']::-ms-clear,
    &[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }
}

.input-focus {
  border: 1px solid $color-red-1 !important;
  box-shadow: $input-shadow !important;
  background-color: $color-white !important;
}

.input-disabled {
  background-color: $color-white-4 !important;
  color: $color-grey-5;
  user-select: none;
  cursor: not-allowed;
}

.textarea-root {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  min-height: 40px;
  background: $color-white;
  border: 1px solid $color-grey-4;
  box-shadow: $box-shadow-4;
  border-radius: 8px;
  color: $color-grey-9;
  text-align: left;
  font-family: $Mukta;
  font-weight: $font-weight-400;
  font-size: $fs-16;
  .textarea-box {
    display: block;
    margin: 0;
    min-width: 0;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: inherit;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    &::placeholder {
      font: inherit;
      color: $color-grey-5;
    }
  }
}

.textarea-not-resizable {
  resize: none;
}
.textarea-resizable {
  resize: vertical;
}
