@import '@styles/resources';

.supplierInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 36px;
  padding: 16px;
  .supplierFactoryTextWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    width: fit-content;
  }
  .supplierFactoryAddressDivider:last-of-type {
    display: none;
  }
  .supplierAddressDeleteContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
