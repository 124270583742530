@import '@styles/resources';

.mainWrapper {
  flex: 1;
  padding: 24px;
  .subMainWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    .rowWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding: 16px 0;
      .textWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-right: 1px solid $color-grey-1;
        padding: 0 16px;
        gap: 6px;
        overflow: hidden;
        &:last-child {
          border-right: 0;
        }
        .labelWrapper {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
