@import '@styles/resources';

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  flex-grow: 1;
  overflow: visible;
  padding: 24px;
  .actionButtonWrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actionWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
