@import '@styles/resources';

.infoText {
  text-align: center;
}

.iconRed {
  color: $color-red-1;
}

.iconContainer {
  top: 50;
  //   position: absolute;
  cursor: pointer;
  margin-top: 10px;
}

.display_name {
  text-align: center;
}

.infoText2 {
  color: $color-yellow-2;
  cursor: pointer;
}
