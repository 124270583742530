@import '@styles/resources';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 0 24px;
}

.desktopCover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .horizontalViewMessage {
        border: 4px solid $color-grey-1;
        border-radius: 4px;
        box-shadow: 0 4px 4px $color-grey-2;
        background-color: $color-grey-1;
        padding: 16px;

        .email {
            margin-top: 16px;
            font-weight: 400;

            .emailLink {
                font-weight: 700;
                color: $color-red-2;
            }
        }
    }
}