@import '@styles/resources';

.btnWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 20px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  background: none;
  background-color: transparent;
  .inputField {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border: none;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font: inherit;
    background: inherit;
    text-align: center;
    &::file-selector-button {
      display: none;
    }
    &::-webkit-file-upload-button {
      display: none;
    }
    &::-ms-browse {
      display: none;
    }
    &::-moz-file-upload-button {
      display: none;
    }
  }
  .btnRipple {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  input.inputField:focus + span.btnRipple {
    box-shadow: $box-shadow-2;
  }
}
