@import '@styles/resources.scss';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  .actionButtonsWrapper {
    margin-top: auto;
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mainButtonsWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
