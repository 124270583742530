@import '@styles/resources';

.forgotPasswordWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  .backButton {
    align-self: stretch;
    display: flex;
    align-items: stretch;
    gap: 8px;
  }
}
