@import '@styles/resources';

.documentTableWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  min-height: 119px;
  .documentTableHeader {
    background-color: $color-grey-1;
    border-bottom: 1px solid $color-grey-2;
    height: 51px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: flex;
    align-items: center;
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      padding: 16px 24px;
      font-weight: $font-weight-500;
      &:last-child {
        justify-content: center;
      }
    }
  }
  .documentContentWrapper {
    flex: 1;
    background-color: $color-white;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .rowWrapper {
      display: flex;
      align-items: center;
      flex: 1;
      min-height: 68px;
      padding: 12px 24px;
      background-color: $color-white;
      border-bottom: 1px solid $color-grey-2;
      &:last-child {
        border: none;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }
      .rowItemWrapper {
        flex: 1;
        display: flex;
        align-items: center;
        .anchorItem {
          padding-left: 16px;
          margin: 0 auto;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover {
            text-decoration: underline;
            text-decoration-color: $color-red-1;
          }
        }
      }
    }
  }
}
