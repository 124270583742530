@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .textWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .textWrapperExternal {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
    }

    .addOrderButton {
      width: 127px;
      height: 42px;
    }
  }

  .tabsWrapper {
    margin: 24px 0;
  }

  .filterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filterButtonWrapper {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    .filterButton {
      width: 104px;
      height: 42px;
    }

    .searchBar {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      .search {
        width: 350px;
        height: 40px;
      }
    }

    .paginationFilter {
      display: flex;
      gap: 8px;
      align-self: center;
      justify-content: center;

      .pageOfTotalPage {
        padding: 8px 8px 0 8px;
        background-color: $color-grey-2;
        border-radius: 16px;
      }
    }
  }

  .orderSection {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
  }
}

.supplierFilterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filterButtonWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .supplierFilterButton {
    width: 104px;
    height: 42px;
  }

  .supplierSearch {
    width: 350px;
    height: 40px;
  }
}