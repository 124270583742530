@import '@styles/resources';

.mainWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  .dropdownWrapper {
    z-index: 1;
    position: absolute;
    min-width: 100%;
    width: max-content;
    height: max-content;
    top: 110%;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $color-white;
    box-shadow: $box-shadow-1;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
  }
}
