@import '@styles/resources';

.outlineWrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 20px 16px 16px;
  background: $color-white;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
}

.headerWrapper {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .editButton {
    cursor: pointer;
  }
}

.contentWrapper {
  flex: 1;
  overflow: hidden;
  margin-top: 16px;
  padding-right: 16px;
  text-align: justify;
  line-height: 1.5;
}
