@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 220px;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  padding: 16px;
  .formWrapper {
    flex: 1;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .fieldsWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
    }
    .actionButtonWrapper {
      display: flex;
      align-items: center;
      align-self: flex-end;
      gap: 8px;
      margin-top: auto;
    }
  }
}
