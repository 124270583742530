@import '@styles/resources';

.orderDetailsWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    flex: 1;
    padding: 16px;
    margin-bottom: 24px;
}

.boxWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    padding: 16px;
}

.consigneeTitleWrapper {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
}

.formActionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}