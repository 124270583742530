@import '@styles/resources';

.loginGroupSection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;

  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;

    .fieldWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;
    }

    .fieldActionButton {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }
  }
}