@import '@styles/resources';

.navWrapper {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  max-height: 72px;
  background-color: $color-white;
  box-shadow: $box-shadow-1;
  padding: 16px;
  .brandLogo {
    height: 32px;
    width: 96px;
  }
  .navItemWrapper {
    display: flex;
    gap: 8px;
    align-items: stretch;
    list-style-type: none;
  }
  .navActionWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    .actionItem {
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
    }
    .notificationIcon {
      &:hover {
        background: $color-grey-1;
      }
    }
  }
}
