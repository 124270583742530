@import '@styles/resources';

.documentTabWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 36px;
  flex: 1;
  margin-bottom: 24px;
  .documentWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    flex: 1;
    .supplierDocumentHeaderWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
