@import '@styles/resources';

.select-label-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  gap: 6px;
  .select-label {
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-16;
    color: $color-slate-brown-1;
    &::after {
      content: attr(data-value);
      color: $color-red-1;
      font-family: $Mukta;
      font-size: $fs-16;
      font-weight: $font-weight-400;
    }
  }
  .select-control-error {
    .select-control {
      border: 1px solid $color-red-1 !important;
    }
  }
  .select-field-helperText {
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-14;
    color: $color-grey-5;
  }
  .input-error {
    border: 1px solid $color-red-6 !important;
  }
  .helper-text-error {
    color: $color-red-1 !important;
  }
}
