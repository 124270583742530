@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding: 0 16px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: 100%;
      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .pocWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        .beforeApproval {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;
        }
        .divider {
          height: 38px;
          border-right: 1px solid $color-grey-1;
        }
        .approveButton {
          border: 1px solid $color-red-1;
          color: $color-red-1;
          width: 190px;
        }
        .viewEditWrap {
          display: flex;
          flex-direction: row;
          gap: 3px;
        }
      }
      .buttonWrapper,
      .buttonWrapperOnApproved {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
      }
      .cardTitle {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
      }
      .illustrationImg {
        width: 40px;
        height: 40px;
      }
    }
  }
  .accordionContent {
    .orderCardWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background: $color-white;
      border-radius: 8px;
      .orderCardFirstWrapper {
        display: flex;
        align-items: stretch;
        height: 100px;
        border-bottom: 1px solid $color-grey-1;
        padding-inline: 24px;
      }
    }
    .buttonWrapper,
    .buttonWrapperOnApproved {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .cardTitle {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;
    }
    .illustrationImg {
      width: 40px;
      height: 40px;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .orderIdWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid $color-grey-1;
    .mainLeftWrapper {
      display: flex;
      gap: 24px;
    }
  }
}
