@import '@styles/resources';

.loadingWrapper {
  flex-grow: 1;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-400;
  color: $color-grey-6;
}
