@import '@styles/resources';

.accordionWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  .accordionHeaderWrapper {
    display: flex;
    align-items: center;
    height: 54px;
    background: $color-white-1;
    border-radius: inherit;
    padding: 12px 16px;
  }
  .accordionContentWrapper {
    border-top: 1px solid $color-grey-2;
    flex-grow: 1;
    background-color: $color-white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
