@import '@styles/resources';

.rectifyRemarkWrapper {
  border-radius: 8px;
  border: 1px solid#E2E2E2;
  background: #fff1f0;
  padding: 16px;
  margin-bottom: 24px;
  .rectifyRemarkTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .rectifyRemarkContent {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}
