@import '@styles/resources';

.searchPlaceholderWrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  text-align: center;
  .secondaryText {
    margin-top: 8px;
    font-family: $Mukta;
    font-size: $fs-18;
    font-weight: $font-weight-400;
    line-height: 22px;
    color: $color-grey-6;
  }
}
