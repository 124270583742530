@import '@styles/resources';

.formWrapper1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .boxWrapper {
    // padding: 16px;
    margin-bottom: 24px;
    margin-top: 24px;
    .packageWrapper {
      padding: 24px;
      .itemWrapper {
        border: 1px solid var(--color-grey-2);
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 12px;
        .packageDetailWrapper {
          border: 1px solid var(--color-grey-2);
          padding: 16px;
          border-radius: 8px;
          margin: 16px 0;
          .packageItemTitle {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .rowWrapper {
      flex: 1;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      margin-top: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .fieldWrapper {
        width: max-content;
      }
    }
  }
  .actionBtnWrapper {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .nextBtn {
      margin-right: 16px;
    }
  }
  .totalContainer {
    padding: 24px;
  }

  //   .itemWrapper {
  //     padding: 16px;
  //     .fieldWrapper {
  //       flex: 1;
  //       display: flex;
  //     }
  //     .pacakageSectionWrapper {
  //       margin-top: 24px;
  //       .titleWrapper {
  //         margin-bottom: 8px;
  //       }
  //     }
  //     .boxWrapper {
  //       //   margin-bottom: 24px;
  //       border-radius: 8px;
  //       border: 1px solid var(--color-grey-2);
  //       padding: 16px;
  //       .rowWrapper {
  //         flex: 1;
  //         display: flex;
  //         gap: 1rem;
  //         align-items: center;
  //         justify-content: space-between;
  //         margin-bottom: 24px;
  //         margin-top: 24px;
  //         &:last-child {
  //           margin-bottom: 0;
  //         }
  //         .fieldWrapper {
  //           margin-right: 24px;
  //         }
  //       }
  //     }
  //   }
  //   .fieldWrapper {
  //     width: 100%;
  //   }
}

.helperTextClass {
  color: $color-red-1 !important;
}
.packageDetailView{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.remainingPackages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 8px;
  .packageDisplay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.itemErrors{
  margin-bottom: 24px;
  font-weight: 600;
}