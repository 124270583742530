  @import '@styles/resources';

  .AutoCompleteWrapper {
    display: flex;
    flex-direction: column;
    .AutoCompleteLabel {
      margin-bottom: 6px;
      .required {
        color: $color-red-2;
      }
    }
    .mainWrapper {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .dropDownWrapper {
        z-index: 1;
        position: absolute;
        top: 110%;
        left: 0;
        right: 0;
        isolation: isolate;
        max-height: 248px;
        overflow-y: auto;
        background: $color-white;
        border: 1px solid $color-grey-2;
        border-radius: 8px;
        &::-webkit-scrollbar {
          width: 8px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-track-piece {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $color-grey-1;
          border-radius: 8px;
        }
        .dropDownItemWrapper {
          padding: 10px 14px;
          height: 40px;
          background-color: $color-white;
          cursor: pointer;
          &:hover {
            background-color: $color-white-1;
          }
        }
      }
    }
  }