@import '@styles/resources';

.mainWrapper {
  margin-top: 16px;
  .boxWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $color-white;
    padding: 16px;
    border-radius: inherit;
    .buttonWrapper {
      align-self: flex-start;
    }
  }
}
