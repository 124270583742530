@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .fieldsContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      .addButton {
        align-self: flex-start;
      }
    }
  }
}

.fieldsWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &:last-of-type {
    .divider {
      display: none;
    }
  }
  .fieldsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rowWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 16px 0;
    .numberFieldWrapper {
      margin-right: 24px;
    }
  }
  .divider {
    margin: 16px 0 8px 0;
  }
}
