@import '@styles/resources';

.accordionBodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 16px;
  .bankDetailCountWrapper {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }
  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    margin-bottom: 12px;
    .fieldsWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 16px;
        .fieldWrapper {
            display: flex;
            align-items: center;
            gap: 24px;
        }
    }   
    }
  .divider:last-of-type {
    display: none;
  }
}
