@import '@styles/resources';

.cardWrapper {
  display: flex;
  padding: 16px 24px;
  width: max-content;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-right: 8px;
  content-visibility: auto;
  contain-intrinsic-size: 100px;
  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    margin-left: 8px;
  }
}
