@import '@styles/resources';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  gap: 24px;

  .bankDetailCountWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .boxWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px;

    .buttonWrapper {
      margin-top: 16px;
      align-self: flex-start;
    }

    .fieldWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &:last-of-type {
        .divider {
          display: none;
        }
      }
    }

    .rowWrapper {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;

      .dummyFieldWrapper {
        flex: 1;
      }

      .btnWrapper {

        // flex: 0.5;
        // display: flex;
        // flex-direction: column;
        // align-items: stretch;
        .attachDocBtn {
          width: 250px;
          margin-top: 22px;
        }
      }

      .downloadPdf {
        width: auto;
        padding: 8px;
        color: $color-red-2;
      }
    }

    .rowWrapper1 {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;

      .dummyFieldWrapper {
        flex: 1;
      }

      .btnWrapper {

        // flex: 0.5;
        // display: flex;
        // flex-direction: column;
        // align-items: stretch;
        .attachDocBtn {
          width: 250px;
          margin-top: 22px;
        }
      }

      .attachedDocumentWrapper {
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex-wrap: wrap;

        .downloadPdf {
          width: auto;
          padding: 8px;
          color: $color-red-2;
        }

        .closeButton {
          padding: 2px;
          background-color: $color-grey-2;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          right: 22px;
          bottom: 10px;
        }
      }
    }
  }

  .actionBtnWrapper {
    margin-top: auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .approveBtn {
      margin-right: 10px;
    }
  }
}


// @import '@styles/resources';

.accordionBodyWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 16px;

  .bankDetailCountWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .detailWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    margin-bottom: 12px;

    .fieldsWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;

      .fieldWrapper {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
  }

  .divider:last-of-type {
    display: none;
  }
}