@import '@styles/resources';

.outlineWrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: $color-white;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
}

.headerWrapper {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .editButton {
    cursor: pointer;
  }
}

.textWrapper {
  margin: 8px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.rowWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
}

.divider {
  margin: 8px 0;
}
