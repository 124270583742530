@import '@styles/resources';

.uploadButton {
  font-size: $fs-16 !important;
}
.documentInnerHeader {
  justify-content: flex-start;
}
.documentTabWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 36px;
  flex: 1;
  margin-bottom: 24px;
  .documentWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    flex: 1;
    .productDocumentHeaderWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
