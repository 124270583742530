@import '@styles/resources';

.supplierWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block: 0px;
  padding-inline: 0;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  .supplierHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .supplierTextWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .supplierDivider {
    margin-top: 18px;
    margin-bottom: 20px;
  }
  .supplierFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filterButtonWrapper {
      display: flex;
      gap: 16px;
      align-items: center;
    }
    .supplierFilterButton {
      width: 104px;
      height: 42px;
    }
    .supplierSearch {
      width: 450px;
      height: 40px;
    }
  }
  .supplierSection {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    margin-top: 24px;
    flex-grow: 1;
  }
  .supplierSearch {
    width: 350px;
    height: 40px;
  }
}
