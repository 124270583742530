@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 775px;
  max-height: 100%;
  overflow: hidden;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    overflow: hidden;
    .fieldsWrapper {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex: 1;
      overflow: auto;
      .attachmentWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;

        .attachmentButtonWrapper {
          .downloadPdf {
            border: 1px solid $color-grey-2;
          }
        }
      }
      .fileUploadState {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 12px;
        height: 40px;
        background: $color-white;
        border: 1px solid $color-grey-2;
        border-radius: 8px;
        overflow: hidden;

        .fileName {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 14px;
        }

        .deleteButton {
          border-left: 1px solid $color-grey-2;
          padding: 0 10px;
          cursor: pointer;
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
      }
      .rowWrapper {
        margin-bottom: 8px;
      }
    }

    .fieldSpacing {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .uploadWrapper {
      margin-bottom: 8px;
    }

    .docUploadWrapper {
      border: 1px solid $color-grey-2;
      border-radius: 8px;

      .docWrapper {
        border-bottom: 1px solid $color-grey-2;
      }
    }

    .actionButtonWrapper {
      box-shadow: $box-shadow-1;
      margin-top: auto;
      padding: 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      .actionButton {
        flex: 1;
      }
    }
  }

  .divider {
    margin: 12px 0;
  }

  .emailBody {
    padding: 16px;
    background-color: $color-white;
    border: 1px solid $color-grey-2;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 16px;
    overflow: auto;
    color: $color-black;

    p {
      margin-bottom: 16px;
      line-height: 1.5;
      font-size: 16px;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 16px;
      margin-bottom: 8px;
    }

    ul,
    ol {
      margin-left: 20px;
      list-style-position: inside;

      li {
        margin-bottom: 8px;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid $color-grey-2;
        padding: 8px;
        text-align: left;
      }
    }
  }
}
