@import '@styles/resources';

.formWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  gap: 20px;
  padding-inline: 72px;
  padding-top: 24px;
  background-color: $color-white-1;
  scroll-behavior: smooth;
  overflow: auto;
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .formContainer {
    display: flex;
    align-items: stretch;
    gap: 24px;
    border-top: 1px solid $color-grey-2;
    flex: 1;
    .sideBarWrapper {
      padding-top: 24px;
      padding-right: 24px;
      border-right: 1px solid $color-grey-2;
    }
  }
}
