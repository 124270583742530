@import '@styles/resources';

.loginWrapper {
  display: flex;
  align-items: stretch;
  flex: 1;
  background-color: $color-white-1;
  overflow: auto;
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    overflow: visible;
    .elchemyLogoWrapper {
      padding-left: 32px;
      padding-top: 24px;
    }
    .loginSection {
      margin: auto;
      width: 360px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 32px;
    }
  }

  .loginCoverWrapper {
    overflow: hidden;
    flex: 1;
    .loginCover {
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
      min-height: 100%;
    }
  }
}

@media screen and (max-width: $md-max) {
  .loginWrapper {
    .loginContainer {
      .elchemyLogoWrapper {
        padding-left: 0px;
        margin: 0 auto;
      }
    }
  }

  .loginCoverWrapper {
    display: none;
  }
}
