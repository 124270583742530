.errorWrapper {
  text-align: center;
  padding: 25px 50px;

  p {
    margin-bottom: 16px;
  }
  .illustration {
    text-align: center;
    width: 30%;
    height: 20%;
    margin-bottom: 10px;
  }
}
