@import '@styles/resources';

.outlineWrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: $color-white;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
}

.headerWrapper {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .editButton {
    cursor: pointer;
  }
}

.rowWrapper {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 16px 0;
  .columnWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    flex-basis: 33%;
    overflow: hidden;
    gap: 6px;
  }
}
