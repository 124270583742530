@import '@styles/resources';

.contentWrapper {
  display: flex;
  flex-direction: column;
  overflow: none;

  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 0 24px;
    width: 100%;

    .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 60%;
      gap: 8px;

      .text {
        text-align: left;
      }

      .image {
       
        .successIcon {
          width: 40px;
          height: 40px;
        }
      }
    }


    .secondaryText {
      display: flex;
      flex-direction: column;
      text-align: end;
      width: 20%;
      font-weight: $font-weight-400;
      font-size: $fs-16;
      color: var(--color-grey-6);
    }
  }

  .textWrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
