@import '@styles/resources';

.placeholderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  .titleHeader {
    font-family: $Mukta;
    font-weight: $font-weight-700;
    font-size: $fs-20;
    line-height: 30px;
    color: $color-slate-brown-1;
    margin-bottom: 8px;
  }
  .descriptionHeader {
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-18;
    line-height: 22px;
    color: $color-grey-6;
    margin-bottom: 16px;
  }
}
