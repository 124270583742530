@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .dividerWrapper {
      margin: 24px 0;
    }
  }
}

.itemMainWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1rem;

  &:last-of-type {
    .divider {
      display: none;
    }
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .fieldSpacing {
    margin-bottom: 24px;
  }

  .rowWrapper {
    flex: 1;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .fieldWrapper {
      margin-right: 24px;
    }
  }
  .divider {
    margin-bottom: 16px;
  }
  .documentsWrapper {
    width: 100%;
    padding: 1rem 0;
  }
}

.textfield {
  margin-bottom: 1rem;
}
