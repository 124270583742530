@import '@styles/resources';

.checkbox-input-wrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  .input-checkbox {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: inherit;
    z-index: 1;
    &:disabled {
      user-select: none;
      cursor: not-allowed;
    }
  }
  .checkbox-ripple {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .input-checkbox:focus + span.checkbox-ripple {
    box-shadow: $box-shadow-2;
  }
  .checkbox-svg {
    width: inherit;
    height: inherit;
  }
}

.input-checkbox-label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-family: $Mukta;
  font-weight: $font-weight-400;
  font-size: $fs-16;
  color: $color-slate-brown-1;
  cursor: pointer;
  vertical-align: middle;
  max-width: max-content;
  .checkbox-label {
    color: inherit;
    font: inherit;
    user-select: none;
    align-self: flex-end;
  }
}
