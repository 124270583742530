@import '@styles/resources';

.textAreaWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 6px;
  flex: 1;
  .textAreaLabel {
    font-family: $Mukta;
    font-weight: $font-weight-500;
    font-size: $fs-14;
    color: $color-slate-brown-1;
    line-height: 16.8px;
    &::after {
      content: attr(data-value);
      color: $color-red-1;
      font: inherit;
    }
  }
  .textAreaHelperText {
    font-family: $Mukta;
    font-size: $fs-14;
    font-weight: $font-weight-400;
    line-height: 17px;
    color: $color-grey-6;
    line-height: 16.8px;
  }
  .inputError {
    border: 1px solid $color-red-6 !important;
  }
  .helperTextError {
    color: $color-red-1 !important;
  }
}
