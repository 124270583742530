@import '@styles/resources';

.image-placeholder {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: transparent;
  // color: $white;
  object-fit: contain;
  object-position: center;
  // font-weight: $weight-600;
  user-select: none;
  cursor: pointer;

  .profile-text {
    user-select: none;
    font: inherit;
    color: inherit;
  }
}
