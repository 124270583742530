@import '@styles/resources';

.supplierInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 36px;
  padding: 16px;
  .supplierTextFieldWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }
}
@media screen and (max-width: $md-max) {
  .supplierInfoWrapper {
    .supplierTextFieldWrapper {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
