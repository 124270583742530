@import '@styles/resources';

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 32px;
  background-color: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  .cardInfoWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 100%;
    .cardProfileWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: $color-grey-1;
      border-radius: 80px;
      font-family: $Mukta;
      font-weight: $font-weight-700;
      font-size: $fs-36;
      color: $color-grey-6;
      cursor: default;
      user-select: none;
    }
    .cardDetailWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }
  .iconsWrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
    .darkButton {
      color: $color-slate-brown-1;
    }
  }
}
