@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    padding-block: 24px;
    flex: 1;

    .boxWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 24px;
        padding: 16px;

        .partiesContainer {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 24px;
            border-radius: 8px;

            .fieldTitle {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            .fieldWrapper {
                display: grid;
                grid-template-columns: 1fr 0.1fr 1fr;
                padding: 16px;
                border-radius: 8px;
                border: 1px solid $color-grey-2;

                .partyContainer {
                    .fieldTitle {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 16px;
                        border: none;
                        padding: 0;
                    }

                    .wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    }
                }

                .divider {
                    height: auto;
                    margin: 0 20px;
                }
            }
        }

        .itemDetailsContainer {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            border-radius: 8px;

            .fieldTitle {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 24px;
                margin-bottom: 10px;
            }

            .fieldWrapper {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        .orderDetailsContainer,
        .remarkBoxContainer {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 16px;
            gap: 24px;
            border: 1px solid $color-grey-2;
            border-radius: 8px;

            .fieldTitle {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 24px;
                margin-bottom: 10px;
            }

            .fieldWrapper {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        .remarkBoxContainer {
            .fieldWrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 24px;

                .remarkListContainer {
                    margin-left: 16px;
                }

                .remarkListContainerNoMargin {
                    margin-left: 0px;
                }
            }
        }
    }

    .actionButtonWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .buttonContainer {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .outlineWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 16px;
        background: $color-white;
        overflow: hidden;
        margin-bottom: 16px;
        gap: 24px;

        .productTable {
            display: flex;
            flex-direction: column;
        }
    }

}

.documentWrapper {
    width: 100%;
}

.bottomDetails {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.remarksPreview {
    display: flex;
    flex-direction: column;
}