@import '@styles/resources';

.formWrapper1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .boxWrapper {
    // padding: 16px;
    margin-bottom: 24px;
    margin-top: 24px;
    .rowWrapper {
      flex: 1;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      margin-top: 24px;
      padding: 0 16px 16px 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .fieldWrapper {
        margin-right: 24px;
      }
      .fieldSpacing {
        width: 100%;
        margin-right: 24px;
        //   margin-bottom: 24px;
      }
    }
  }
  .actionBtnWrapper {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .nextBtn {
      margin-right: 16px;
    }
  }

  //   .itemWrapper {
  //     padding: 16px;
  //     .fieldWrapper {
  //       flex: 1;
  //       display: flex;
  //     }
  //     .pacakageSectionWrapper {
  //       margin-top: 24px;
  //       .titleWrapper {
  //         margin-bottom: 8px;
  //       }
  //     }
  //     .boxWrapper {
  //       //   margin-bottom: 24px;
  //       border-radius: 8px;
  //       border: 1px solid var(--color-grey-2);
  //       padding: 16px;
  //       .rowWrapper {
  //         flex: 1;
  //         display: flex;
  //         gap: 1rem;
  //         align-items: center;
  //         justify-content: space-between;
  //         margin-bottom: 24px;
  //         margin-top: 24px;
  //         &:last-child {
  //           margin-bottom: 0;
  //         }
  //         .fieldWrapper {
  //           margin-right: 24px;
  //         }
  //       }
  //     }
  //   }
  //   .fieldWrapper {
  //     width: 100%;
  //   }
}
