@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 72px 0;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    .buttonWrapper {
      width: 111px;
      height: 42px;
    }
  }
}
