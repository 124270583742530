@import '@styles/resources';

.siWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    gap: 20px;
    padding-inline: 72px;
    padding-top: 24px;
    background-color: $color-white-1;
    scroll-behavior: smooth;
    overflow: auto;
}