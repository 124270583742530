@import '@styles/resources';

.avatarBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    box-shadow: 0px 0px 0px 2px #f1f2f2;
  }
  .avatarFallback {
    background-color: #fcfcfc;
    border: 1px solid #e2e2e2;
    font-family: $Mukta;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #2a2522;
    width: 100%;
    height: 100%;
  }
}
