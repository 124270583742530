@import '@styles/resources';

.formWrapper {
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .actionBtnWrapper {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nextBtn {
      margin-right: 16px;
    }
  }
  .itemWrapper {
    padding: 16px;
    .rowWrapper {
      display: flex;
      align-items: center;
    }
    .fieldWrapper {
      flex: 1;
      display: flex;
    }
    .pacakageSectionWrapper {
      margin-top: 12px;
      .titleWrapper {
        margin-bottom: 8px;
      }
    }
  }
  .fieldWrapper {
    width: 100%;
  }
}
