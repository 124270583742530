@import '@styles/resources';

.productInfoContainer {
  flex: 1;
  padding: 24px;
  .detailBoxWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    gap: 32px;
    .alignText {
      text-align: center;
    }
    .productDocCount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
