@import '@styles/resources';

.tag-root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 8px;
  gap: 3px;
  min-width: 61px;
  min-height: 24px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  .tag-label {
    align-self: stretch;
    font-family: $Mukta;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #344054;
    margin-block: auto;
  }
  .tag-close-wrapper {
    cursor: pointer;
    margin-left: auto;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    .tag-close-icon {
      width: 12px;
      height: 12px;
    }
  }
}
