@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &:last-of-type {
    .divider {
      display: none;
    }
  }
  .headerWrapper {
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
      margin-top: 0;
    }
  }
  .fieldWrapper {
    margin: 16px 0;
  }
  .divider {
    margin: 16px 0;
  }
}
