@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  content-visibility: auto;
  contain-intrinsic-size: 246px;
  &:last-of-type {
    margin-bottom: 16px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid $color-grey-1;
    .buttonWrapper, .buttonWrapperOnApproved{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .buttonLeftWrapper, .buttonRightWrapper{
            border-right: 1px solid $color-grey-1;
            padding: 0 24px;
        }
    }
    .selectDrop{
        border: none;
        outline: none;
    }
    .cardTitle{
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
    }
    .illustrationImg{
        width: 40px;
        height: 40px;
    }
    .selectWrapper, .assignWrapper, .dateWrapper{
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
    }
    .selectWrapper{
        width: 30%;
        margin-right: 15%;
    }
  }
  .textWrapper {
    padding: 16px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
      align-self: stretch;
      margin-left: auto;
      border-left: 1px solid $color-grey-1;
      padding-left: 16px;
    }
    .addressWrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .mainContent {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-grey-1;
    justify-content: space-between;
    
    }
    .mainLeftWrapper{
        display: flex;
        gap: 24px;
    }
    
  }
  
  .orderCardWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $color-white;
    border-radius: 8px;
    .orderCardFirstWrapper {
      display: flex;
      align-items: stretch;
      height: 100px;
      border-bottom: 1px solid $color-grey-1;
      padding-inline: 24px;
      .orderCardContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        .statusContainer {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 4px;
          padding-left: 24px;
        }
        .profileIcon {
          height: 40px;
          width: 40px;
        }
        .orderCardDetailColumn {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 4px;
          .titleWrapper {
            display: flex;
            align-items: center;
            gap: 5px;
            .primaryTitleLabel {
              font-weight: $font-weight-400;
              font-size: $fs-16;
              color: $color-grey-6;
            }
          }
        }
    }
      }
    
  }
  
  
