@import '@styles/resources';

.boxWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  .rowWrapper {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }
  .hazardousBoxWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
  }
  .uploadContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 5px;
    .uploadContainerRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid $color-grey-2;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .label {
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-14;
    color: $color-slate-brown-1;
    &::after {
      content: attr(data-value);
      color: $color-red-1;
      font-family: $Mukta;
      font-size: $fs-14;
      font-weight: $font-weight-400;
    }
  }
  .uploadSuccess {
    margin-right: 5px;
  }
  .errorText {
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-14;
    color: $color-red-1 !important;
  }
  .inputError {
    border: 1px solid $color-red-6 !important;
  }
}
