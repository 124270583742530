@import '@styles/resources';

.supplierWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  gap: 24px;
}
