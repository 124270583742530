@import '@styles/resources';

.userInfoContainer {
  flex: 1;
  padding: 24px;
  .detailBoxWrapper {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    gap: 32px;
    .rowWrapper {
      display: flex;
      align-items: center;
      flex: 1;
      .textColumnWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        flex: 1;
        border-left: 1px solid $color-grey-2;
        padding-left: 16px;
        &:first-child {
          border-left: none;
          padding-left: 0;
        }
      }
    }
    .linkLabel {
      font-family: $Mukta;
      font-size: $fs-16;
      font-weight: $font-weight-400;
      color: $color-slate-brown-1;
      text-decoration: none;
    }
    .addressWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      flex: 1;
    }
  }
}
