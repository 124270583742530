@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .customerTextWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .addCustomerButton {
      width: 127px;
      height: 42px;
    }
  }
  .divider {
    margin-top: 18px;
    margin-bottom: 20px;
  }
  .filterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filterButton {
      width: 104px;
      height: 42px;
    }
    .search {
      width: 350px;
      height: 40px;
    }
    .paginationFilter{
      display: flex;
      gap: 8px;
      align-self: center;
      justify-content: center;
      .pageOfTotalPage{
        padding: 8px 8px 0 8px;
        background-color: $color-grey-2;
        border-radius: 16px;
      }   
    }
  }
  .customerSection {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
  }
  .taskChipWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
