:root {
  --color-red-1: #ef4136;
  --color-red-2: #e02d28;
  --color-red-3: #f15a29;
  --color-red-4: #f04438;
  --color-red-5: #ffeeed;
  --color-red-6: #fda29b;
  --color-red-7: #ffb0ab;
  --color-red-8: #d23930;
  --color-red-9: #d9291c;
  --color-red-10: #fff1f0;

  --color-white-1: #fcfcfc;
  --color-white-2: #f6f6f6;
  --color-white-3: #ffffff;
  --color-white-4: #f9fafb;
  --color-white-5: #f9fafa;

  --color-grey-1: #f1f2f2;
  --color-grey-2: #e2e2e2;
  --color-grey-3: #f6f6f6;
  --color-grey-4: #d0d5dd;
  --color-grey-5: #667085;
  --color-grey-6: #8d8885;
  --color-grey-7: #344054;
  --color-grey-8: #c7c7c7;
  --color-grey-9: #101828;

  --color-slate-brown-1: #2a2522;
  --color-slate-brown-2: #232323;
  --color-slate-brown-3: #312721;

  --color-blue-1: #3a49e3;
  --color-blue-2: #eff0ff;

  --color-white: #ffffff;
  --color-black: #000000;

  --elchemy-text-2: #8d8885;

  --color-yellow-1: #fff5e9;
  --color-yellow-2: #f79009;

  --color-green-1: #eafaf2;
  --color-green-2: #12b76a;
}

$color-red-1: var(--color-red-1);
$color-red-2: var(--color-red-2);
$color-red-3: var(--color-red-3);
$color-red-4: var(--color-red-4);
$color-red-5: var(--color-red-5);
$color-red-6: var(--color-red-6);
$color-red-7: var(--color-red-7);
$color-red-8: var(--color-red-8);
$color-red-9: var(--color-red-9);
$color-red-10: var(--color-red-10);

$color-white-1: var(--color-white-1);
$color-white-2: var(--color-white-2);
$color-white-3: var(--color-white-3);
$color-white-4: var(--color-white-4);
$color-white-5: var(--color-white-5);

$color-grey-1: var(--color-grey-1);
$color-grey-2: var(--color-grey-2);
$color-grey-3: var(--color-grey-3);
$color-grey-4: var(--color-grey-4);
$color-grey-5: var(--color-grey-5);
$color-grey-6: var(--color-grey-6);
$color-grey-7: var(--color-grey-7);
$color-grey-8: var(--color-grey-8);
$color-grey-9: var(--color-grey-9);

$color-slate-brown-1: var(--color-slate-brown-1);
$color-slate-brown-2: var(--color-slate-brown-2);
$color-slate-brown-3: var(--color-slate-brown-3);

$color-blue-1: var(--color-blue-1);
$color-blue-2: var(--color-blue-2);

$color-white: var(--color-white);
$color-black: var(--color-black);

$elchemy-text-2: var(--elchemy-text-2);

$color-yellow-1: var(--color-yellow-1);
$color-yellow-2: var(--color-yellow-2);

$color-green-1: var(--color-green-1);
$color-green-2: var(--color-green-2);
