@import '@styles/resources';

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 650px;
  max-height: 100%;
  overflow: hidden;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    overflow: hidden;
  }
  .addPocText {
    color: var(--color-red-1);
    text-align: center;
    margin-top: 12px;
    cursor: pointer;
    font-weight: 500;
  }
  .pocListContainer {
    display: flex;
  }
  .pocDeleteIcon {
    margin-top: 33px;
    margin-left: 10px;
  }
  .typeField {
    margin-bottom: 15px;
  }
  .fileUploadState {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    height: 40px;
    background: $color-white;
    border: 1px solid $color-grey-2;
    border-radius: 8px;

    .fileName {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 14px;
    }
    .deleteButton {
      border-left: 1px solid $color-grey-2;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $color-white;
    flex: 1;
    padding: 16px;
    overflow: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-grey-1;
      border-radius: 8px;
    }
    .fieldWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      margin-top: 16px;
      .fieldSpacing {
        margin-bottom: 12px;
      }
      .pocInputNote {
        font-size: 14px;
        color: #969696;
        font-style: italic;
      }
    }
    .radioGroupWrapper {
      margin: 16px 0;
    }
  }
  .modalFooter {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: $color-white;
    box-shadow: $box-shadow-1;
    height: 74px;
    & > button {
      flex: 1;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  .modalProductTable {
    display: flex;
    flex-direction: column;
  }
}