@import '@styles/resources';

.mainWrapper {
  flex: 1;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .headerWrapper {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .contentWrapper {
    flex: 1;
    display: flex;
    border-top: 1px solid $color-grey-1;
    .sidebarWrapper {
      border-right: 1px solid $color-grey-1;
      padding: 24px 24px 24px 0;
    }
    .boxWrapper {
      flex: 1;
      overflow: hidden;
    }
  }
  .bankPlaceholderWrapper {
    flex: 1;
    margin: 20px 0;
  }
}
