@import '@styles/resources';

.documentPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  flex: 1;
  .placeholderLabel {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .button {
    max-width: 149px;
  }
}
