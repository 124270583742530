@import '@styles/resources';

.cardWrapper {
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 32px;
  background-color: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  .cardInfoWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 100%;
    .cardProfileWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: $color-grey-1;
      border-radius: 80px;
      font-family: $Mukta;
      font-weight: $font-weight-700;
      font-size: $fs-36;
      color: $color-white-1;
      background-color: $color-red-1;
      cursor: default;
      user-select: none;
    }
  }
  .cardDetailWrapper {
    display: flex;
    flex-direction: column;
    border-left: 1px solid $color-grey-8;
    height: 100%;
    padding-inline: 24px;
    gap: 6px;
    flex: 1;
    .cardContactWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 12px;
      .contactWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        .linkLabel {
          font-family: $Mukta;
          font-size: $fs-16;
          font-weight: $font-weight-400;
          color: $color-slate-brown-1;
          text-decoration: none;
        }
      }
    }
  }
}
