@import '@styles/resources';

.orderWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  flex: 1;
  padding-block: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  scroll-behavior: smooth;
  overflow: auto;

  .orderIndividualHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.refreshLink {
  text-align: center;
}

.refreshLink span {
  color: $color-red-1;
  cursor: pointer;
}

.refreshLink span:hover {
  text-decoration: underline;
}
