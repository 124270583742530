@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .addressWrapper {
      margin: 24px 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }
    .divider:last-of-type {
      display: none;
    }
    .shippingAddressHeader {
      margin: 24px 0 0 0;
      display: flex;
      align-items: center;
      gap: 24px;
      width: fit-content;
    }
    .plusShippingBtn {
      align-self: flex-start;
    }
    .rowWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .fieldWrapper {
        margin-right: 24px;
      }
    }
    .addressHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 8px 0;
    }
  }
}
