@import '@styles/resources';

.select-container {
  flex: 1;
  .select-control {
    height: auto;
    background-color: $color-white;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      border: 1px solid $color-grey-8;
    }
    .select-value-container {
      display: flex;
      gap: 4px;
      height: 100%;
      padding-left: 12px;
      flex-wrap: nowrap;
      .select-multi-value {
        height: 24px;
        align-items: center;
        border-radius: 16px;
        background-color: $color-grey-1;
        border: 1px solid $color-grey-2;
        font-family: $Mukta;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        text-align: center;
        color: $color-slate-brown-1;
        padding: 2px 8px;
        gap: 4px;
      }
      .tag-count {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 40px;
        border-radius: 16px;
        background-color: $color-white-1;
        border: 1px solid $color-grey-2;
        font-family: $Mukta;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        text-align: center;
        color: $color-slate-brown-1;
      }
      .select-single-value {
        font-family: $Mukta;
        font-style: normal;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        color: $color-slate-brown-1;
      }
      .select-placeholder {
        font-family: $Mukta;
        font-style: normal;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        color: $color-grey-6;
        border: none;
      }
      .select-placeholder-focused {
        color: $color-slate-brown-1;
      }
    }
    .select-indicators-wrapper {
      width: 40px;
      justify-content: center;
      cursor: pointer;
    }
  }
  .select-control-focused {
    border: 1px solid $color-red-1 !important;
    box-shadow: $box-shadow-2;
  }
  .select-control-disabled {
    background-color: $color-white-5;
    border: 1px solid $color-grey-2;
    pointer-events: none;
    cursor: not-allowed;
    user-select: none;
  }

  .select-menu {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: $box-shadow-1;
    margin-block: 10px;
    .select-menu-list {
      padding-block: 10px;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
      }
      .select-option-item {
        background-color: $color-white;
        height: 40px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $Mukta;
        font-style: normal;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        color: $color-slate-brown-1;
        padding: 14px;
        &:hover {
          background-color: $color-white-1;
        }
      }
      .select-option-selected {
        background-color: $color-white-1 !important;
      }
    }
  }
}
