@import '@styles/resources';

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  flex: 1;
  padding: 16px;
  margin-bottom: 24px;
  .previewWrapper {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 24px;
  }
  .boxWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    padding: 16px;
  }
  .consigneeTitleWrapper {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
  }
  .purchaserWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    gap: 32px;
    background: $color-white;
    border-radius: inherit;
    flex: 1;

    .nameFieldWrapper {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
  .formActionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .actionButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttonContainer {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
