@import '@styles/resources';

.orderItemFormWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    flex: 1;
    padding: 16px;
    margin-bottom: 24px;

    .orderItemDetailWrapper {
        display: flex;
        padding: 16px;
        flex-direction: column;
        gap: 24px;
    }

    .boxWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 24px;
        padding: 16px;
    }

    .formActionWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}