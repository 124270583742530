@import '@styles/resources';

.chip-root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 3px;
  max-width: max-content;
  min-height: 27px;
  border-radius: 16px;
  cursor: default;

  .chip-label {
    font: inherit;
    color: inherit;
  }

  .close-icon-wrapper {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: #f8f8ff;
    .close-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.chip-clickable {
  cursor: pointer !important;

  &:hover {
    box-shadow: $box-shadow-4;
  }
}

.chip-filled {
  font-family: $Mukta;
  font-size: $fs-16;
  color: $color-white-1;
  font-weight: $font-weight-400;
  background-color: #d9291c;
  border: 1px solid #d9291c;
  text-align: center;
}

.chip-outlined {
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-400;
  color: $color-slate-brown-1;
  background-color: $color-white;
  border: 1px solid $color-grey-8;
  text-align: center;
}

.chip-filled-blue {
  user-select: none;
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-400;
  color: $color-blue-1;
  background-color: $color-blue-2;
  border: none;
  text-align: center;
  vertical-align: middle;
}
.chip-filled-red {
  user-select: none;
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-400;
  color: #d9291c;
  background-color: $color-red-5;
  border: 1px solid #d9291c;
  text-align: center;
  vertical-align: middle;
}
.chip-filled-yellow {
  user-select: none;
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-400;
  color: #f79009;
  background-color: #fff5e9;
  border: 1px solid #f79009;
  text-align: center;
  vertical-align: middle;
}

.chip-filled-green {
  user-select: none;
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-400;
  color: #12b76a;
  background-color: #eafaf2;
  border: 1px solid #eafaf2;
  text-align: center;
  vertical-align: middle;
}

.chip-center {
  margin-right: auto;
  margin-left: auto;
}
