@import '@styles/resources';

.breadcrumbWrapper {
  display: flex;
  align-items: center;
  .linkContainer {
    display: flex;
    align-items: stretch;
    flex: 1;
    list-style-type: none;
    gap: 8px;
  }
  .breadCrumbActiveItem {
    font-family: $Mukta;
    font-weight: $font-weight-500;
    font-size: $fs-16;
    color: $color-slate-brown-1;
  }
}

.breadcrumbItemWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-family: $Mukta;
  font-size: $fs-16;
  font-weight: $font-weight-400;
  color: $color-grey-6;
  .breadcrumbItem {
    font: inherit;
    color: inherit;
    text-decoration: none;
    cursor: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
  &::after {
    content: attr(data-separator);
    display: inline-block;
    color: $color-grey-2;
    margin-left: 8px;
  }
  &:last-child {
    pointer-events: none;
    text-decoration: none;
    cursor: text;
    &::after {
      content: '';
    }
  }
}
