@import '@styles/resources';

.mainWrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 275px;
    border-radius: 8px;
    border: 1px solid $color-grey-2;
    background-color: $color-white-5;
    gap: 8px;

    .heading {
        background-color: $color-grey-1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -8px;
        padding: 8px;
        border-bottom: 1px solid $color-grey-2;
    }

    .titleContainer {
        display: flex;
        height: 27px;
        align-items: center;
        justify-content: center;
        background-color: $color-yellow-1;
        border-bottom: 1px solid $color-grey-2;
        width: 100%;

        .title {
            color: #F79009;

        }

        .titleIcon {
            margin-right: 8px;
        }
    }

    .document {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        gap: 8px;
        margin-top: 8px;

        .textContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-left: 16px;

            .textIcon {
                fill: #F9A63A;
            }

            .text {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                color: $color-slate-brown-1;
                margin-right: 8px;
            }
        }
    }

    .links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        row-gap: 8px;
        width: 100%;
        border-bottom: 1px solid $color-grey-2;
        padding-bottom: 12px;

        .downloadPdf {
            border: 1px solid $color-grey-2;
        }

        .pdfLink {
            border: 1px solid $color-grey-2;
            width: 75%;
        }

        .generateWord {
            border: 1px solid $color-grey-2;
        }

        .divider {
            padding: 16px 0;
            height: 25px;
        }
    }

    .editContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 8px;

        .editTagsButton {
            margin-right: 16px;
            margin-bottom: 8px;
            color: $color-red-1;
        }

        .editText {
            color: $color-red-1;
        }

        .deleteButton {
            margin-bottom: 8px;
            color: $color-red-1;
        }

        .deleteText {
            color: $color-red-1;
        }

        .deleteTextDisabled {
            color: $color-grey-6;
        }
    }
}