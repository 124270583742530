@import '@styles/resources';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .addressWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
      margin-top: 16px;
    }
  }
  .actionButtonWrapper {
    margin-top: auto;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actionWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
