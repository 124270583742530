@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  padding-block: 24px;
  flex: 1;

  .boxWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    padding: 16px;

    .invoiceContainer {
      display: grid;
      grid-template-columns: 1fr 0.1fr 1fr;

      .fieldWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .invoiceDivider {
        height: 50px;
        margin: 0 20px;
      }
    }

    .partiesContainer {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 16px;
      gap: 24px;
      border: 1px solid $color-grey-2;
      border-radius: 8px;

      .fieldTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .fieldWrapper {
        display: grid;
        grid-template-columns: 1fr 0.1fr 1fr;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid $color-grey-2;

        .partyContainer {
          .fieldTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          }

          .wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        }

        .divider {
          height: 250px;
          margin: 0 20px;
        }
      }
    }

    .itemDetailsContainer {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 16px;
      gap: 24px;
      border: 1px solid $color-grey-2;
      border-radius: 8px;

      .fieldTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        margin-bottom: 10px;
      }

      .fieldTable {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border: 1px solid $color-grey-2;
        border-radius: 8px;

        .tableHeader,
        .tableContent {
          display: grid;
          grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
          padding: 12px 24px 12px 16px;
          background-color: $color-grey-1;
        }

        .tableContent {
          background-color: $color-white;
          border-bottom: 1px solid $color-grey-2;

          .secondaryText {
            font-family: $Mukta;
            font-size: $fs-16;
            font-weight: $font-weight-500;
            color: $color-grey-6;
            line-height: 19px;
            margin-top: 12px;
          }
        }

        .gstDetails,
        .totalContainer {
          display: grid;
          grid-template-columns: 4fr 1fr;
          padding: 16px 24px;
        }

        .totalContainer {
          background-color: $color-grey-1;
        }
      }

      .fieldWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .orderDetailsContainer,
    .bankDetailsContainer,
    .purchaseBoxContainer,
    .remarkBoxContainer {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 16px;
      gap: 24px;
      border: 1px solid $color-grey-2;
      border-radius: 8px;

      .fieldTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        margin-bottom: 10px;
      }

      .fieldWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .purchaseBoxContainer {
      .fieldWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .remarkBoxContainer {
      .fieldWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        .remarkListContainer {
          margin-left: 16px;
        }
        .remarkListContainerNoMargin {
          margin-left: 0px;
        }
      }
    }
  }

  .actionButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttonContainer {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}

.invoiceWords {
  text-transform: capitalize;
}

.editRed {
  cursor: pointer;
}
