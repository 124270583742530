@import '@styles/resources';

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 600px;
  max-height: 100%;
  overflow: hidden;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    overflow: hidden;
    .formHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .formHeaderButton {
        margin-top: 16px;
        margin-right: 16px;
      }
      .formTitle {
        font-size: 16px;
        font-weight: 500;
        color: $color-black;
      }
      .modalChipWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        margin-left: 16px;
        gap: 8px;
      }
    }
    .modalContent {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: $color-white;
      flex: 1;
      padding: 16px;
      overflow: auto;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
      }
      .fieldWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
        margin-top: 16px;
        .fieldSpacing {
          margin-bottom: 12px;
        }
        .pocInputNote {
          font-size: 14px;
          color: #969696;
          font-style: italic;
        }
      }
    }
    .modalFooter {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background: $color-white;
      box-shadow: $box-shadow-1;
      height: 74px;
      & > button {
        flex: 1;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
  .modalFooter {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: $color-white;
    box-shadow: $box-shadow-1;
    height: 74px;
    & > button {
      flex: 1;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
//Styling for Image Checkboxes
ul {
  list-style-type: none;
  li {
    display: inline-block;
    input[type='checkbox'] {
      display: none;
    }
    label {
      border: 1px solid $color-white;
      padding: 10px;
      display: block;
      position: relative;
      margin: 10px;
      cursor: pointer;
      &:before {
        color: $color-white;
        content: ' ';
        display: block;
        border-radius: 50%;
        border: 1px solid $color-grey-1;
        position: absolute;
        top: -5px;
        left: -5px;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 28px;
        transition-duration: 0.4s;
        transform: scale(0);
      }
      img {
        height: 235px;
        width: 235px;
        transition-duration: 0.2s;
        transform-origin: 50% 50%;
      }
    }
    input[type='checkbox']:checked + label {
      border-color: #ddd;
      &:before {
        content: '✓';
        background-color: $color-red-2;
        transform: scale(1);
      }
      img {
        transform: scale(0.9);
        z-index: -1;
      }
    }
  }
}

.documentNameWrapper {
  display: flex;
}
